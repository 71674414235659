<template>
    <div class="container" style="padding-bottom: 20px;">
        <TopRow :key="componentKey" />
        <div class="row" style="margin-bottom: 15px">
            <router-view />
        </div>
    </div>
</template>

<script>
import TopRow from "./components/TopRow.vue"

console.log(`VUE_APP_ROOT_URL: ${process.env.VUE_APP_ROOT_URL}`);
console.log(`BASE_URL: ${process.env.BASE_URL}`);

export default {
    components: { TopRow, },
    data() {
        return {
            componentKey: 0,
        }
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        }
    }
}
</script>

<style>
body {
    background: #b0b9bb;
    margin: 8px;
}

#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #b0b9bb;
}
</style>
