//import { getCurrentInstance } from "vue"; doing imports in libs is somehow bad for vue3

function decycle(obj, stack = []) {
    if (!obj || typeof obj !== "object") return obj;

    if (stack.includes(obj)) return null;

    let s = stack.concat([obj]);

    return Array.isArray(obj)
        ? obj.map((x) => decycle(x, s))
        : Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, decycle(v, s)]));
}

async function getUser(id) {
    return new Promise((resolve) => {
        let url = `${process.env.VUE_APP_ROOT_URL}api/users/${id}`;
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                let msg = `Error Loading User ${id} - ${JSON.stringify(err)}`;
                this.loadingCodesMessage += msg;
                console.log(msg);
                resolve(null);
            });
    });
}

async function getAndNormalizeCodes(theUsers, expandUsers) {
    return new Promise((resolve) => {
        let returnObj = {
            theUsers: theUsers,
            theCodes: [],
            theMessages: "",
        };
        try {
            let url = `${process.env.VUE_APP_ROOT_URL}api/codes`;
            fetch(url)
                .then((res) => res.json())
                .then(async (data) => {
                    let removedCodes = [];
                    returnObj.theCodes = data.filter((code) => {
                        return (
                            code.codeid &&
                            !code.codeid.toLowerCase().includes("null") &&
                            code.redirecturl &&
                            !code.redirecturl.toLowerCase().includes("null")
                        );
                    });
                    //go get relevant users
                    if (returnObj.theCodes.length > 0) {
                        //loop through codes to find additional users
                        await Promise.all(
                            returnObj.theCodes.map(async (code) => {
                                try {
                                    if (expandUsers) {
                                        //load all relevant users.
                                        let ownerUserId = code.owneruserid;
                                        let theOwnerUser = returnObj.theUsers.filter((user) => {
                                            return user.id == ownerUserId;
                                        });
                                        if (theOwnerUser) {
                                            if (theOwnerUser.owner_user_id && theOwnerUser.owner_user_id != 0) {
                                                let theOwnerOwnerUser = getUser(theOwnerUser.owner_user_id);
                                                returnObj.theUsers.push(theOwnerOwnerUser);
                                                code.hasManager = true;
                                            }
                                        } else {
                                            removedCodes.push(code.id);
                                        }
                                    } else {
                                        //only leave codes that relate to current user list
                                        let codeUsers = returnObj.theUsers.filter((user) => {
                                            return code.owneruserid == user.id; //|| code.owneruserid == user.owner_user_id
                                        });
                                        if (!codeUsers || codeUsers.length == 0) {
                                            removedCodes.push(code.id);
                                        }
                                    }
                                } catch (err) {
                                    console.log(`review codes error ${JSON.stringify(err)}`);
                                    //returnObj.theMessages += `error analyzing codes @ codeid ${code.id} err ${JSON.stringify(err)}`
                                }
                            })
                        );
                        if (removedCodes.length > 0) {
                            returnObj.theCodes = returnObj.theCodes.filter((code) => {
                                return !removedCodes.includes(code.id);
                            });
                        }
                        if (returnObj.theCodes.length <= 0) {
                            returnObj.theMessages += "No Codes Loaded";
                        } else {
                            returnObj.theMessages += `${returnObj.theCodes.length} Codes Loaded.`;
                        }
                        resolve(returnObj);
                    } else {
                        returnObj.theMessages += `No Codes Loaded`;
                        resolve(returnObj);
                    }
                })
                .catch((err) => {
                    returnObj.theMessages += `Loading Codes Failed. ${JSON.stringify(err)}`;
                    console.log(JSON.stringify(err));
                    resolve(returnObj);
                });
        } catch (err) {
            returnObj.theMessages += `error Loading Codes ${JSON.stringify(err)}`;
            console.log(JSON.stringify(err));
            resolve(returnObj);
        }
    });
}

/* doing imports in libs is somehow bad for vue3
function methodThatForcesUpdate() {
    const instance = getCurrentInstance();
    instance.proxy.forceUpdate();
}
*/

module.exports = {
    decycle,
    getUser,
    getAndNormalizeCodes,
    //methodThatForcesUpdate,
};
