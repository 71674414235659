<template>
    <div style="padding-top:5px;">
        <div class="row" v-if="this.windowWidth > 819">
            <nav class="navtopInner col-12">
                <router-link v-for="(route, index) in theRoutes" :key="index" :to="{ name: route.name }">{{
                    route && route.components && route.components.default && route.components.default.display ?
                        route.components.default.display : route.meta.display }}<!-- span
                v-if="index < this.theRoutes.length-1" &nbsp;|&nbsp;--></router-link>
            </nav>
        </div>
        <div class="row" v-else>
            <div class="row" data-bs-target="#collapseTarget" data-bs-toggle="collapse">
                <div class="col-11">&nbsp;</div>
                <div class="col-1 icons">
                    <div class="material-icons-menu material-icons">menu</div>
                </div>
            </div>
            <div class="collapse py-2" id="collapseTarget">
                <nav class="navtopCompressed">
                    <router-link v-for="(route, index) in theRoutes" :key="index" :to="{ name: route.name }">{{
                        route && route.components && route.components.default && route.components.default.display ?
                            route.components.default.display : route.meta.display }}<!-- span
                v-if="index < this.theRoutes.length-1" &nbsp;|&nbsp;--></router-link>
                </nav>
            </div>

        </div>
    </div>
</template>

<script>
import router from "../router"
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

//let theRoutes = router.getRoutes();
//theRoutes = theRoutes.filter((route) => route && route.meta && (!route.meta.hideNavbar || (route.meta.authRequired && cookies.get("theAuthCookie"))));

function buildRoutes() {
    let routes = router.getRoutes().filter((route) => {
        let authRequired = route &&
            route.meta &&
            route.meta.authRequired == true;
        let authorized = authRequired &&
            cookies &&
            cookies.get("theAuthCookie") != null;
        let isAuthorized = authRequired == false || (authRequired == true && authorized == true);
        let willShow = route.meta && !route.meta.hideNavbar || (route.meta.hideNavbar != true);
        let showing = isAuthorized && willShow;
        //console.log(` route name: ${route.name},\n authRequired: ${authRequired},\n authorized: ${authorized},\n isAuthorized: ${isAuthorized}, willShow: ${willShow},\n showing: ${showing}`)

        return showing;
    })
    return routes
}

export default {
    data() {
        return {
            theRoutes: buildRoutes(),
            isMenuOpen: false,
            windowWidth: window.innerWidth,
        }
    },
    methods: {
        redirect() {
            this.$router.push({ name: "Home" })
        },
        back() {
            this.$router.go(-1)
        },
        forward() {
            this.$router.go(1)
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
            console.log(`toggleMenu ${this.isMenuOpen} `)
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
}

</script>

<style>
.navtopOuter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 700;
    padding: 30px;
}

.navtopInner {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 700;
}


.navtopInner a {
    font-weight: 700;
    color: #2c3e50;
    text-decoration: none;
    padding: 10px;
    border-radius: 4px;
    font-size: 1.5em;
    text-align: left;
}

.navtopInner a.router-link-exact-active {
    color: white;
    background: rgb(46, 108, 183);

}

.navtopCompressed {
    display: grid;
    justify-content: space-between;
    align-items: center;

    width: 40%;
    background: grey;
    padding: 5px;

    float: right;
    position: fixed;
    z-index: 1;
    top: 0px;
    left: 60%;
    height: 95%;
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.5em;
    text-align: left;
}

.navtopCompressed a {
    font-weight: 700;
    color: #2c3e50;
    text-decoration: none;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.navtopCompressed a.router-link-exact-active {
    color: white;
    background: #32370e;
}

button {
    margin: 0 10px;
    padding: 10px;
    border: none;
    border-radius: 4px;
}

/* material icons setup */
.material-icons-menu {
    font-size: 48px;
    margin-left: 10px;
    color: #2c3e50;
    cursor: pointer;
}

.material-icons-menu:hover {
    color: #777
}
</style>