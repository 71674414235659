<template>
    <div v-if="owneruserid == 123" class="row border rounded border-primary" style="margin-top: 20px;">
        <div class="row" @click="handleDisplayUserStuffDetail">
            <div style="text-align:left;" v-if="displayUserStuffDetail">
                <div style="text-align:left;">Special Page Stuff</div>
                <pre>{{ jsonPretty(this ? JSON.stringify(
                    //                    decycle(
                    this
                    //                        )
                ) : 'No Special Stuff', 4) }}</pre>
            </div>
            <div style="text-align:left;" v-else>Special Page Stuff: {{ this ?
                JSON.stringify(
                    // decycle(
                    this
                    // )
                ).substring(0, 25) : "No Special Stuff" }}</div>
        </div>
    </div>
    <div class="row">&nbsp;</div>
    <div class="row border rounded border-secondary xForm">
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="BusName">Business Name / Business Message</label>
                <input id="BusName" class="form-control" type="text" required v-model="BusName" />
                <div v-if="BusNameNote" class="noteError">{{ BusNmeNote }}</div>
            </div>
            <div class="form-group">
                <label for="selectedStyleSelectOption">Style</label>
                <select id="selectedStyleSelectOption" class="form-control" v-model="selectedStyleSelectOption">
                    <option v-for="styleSelectOption in styleSelectOptions" :value="styleSelectOption.value">
                        {{ styleSelectOption.name }}
                    </option>
                </select>
            </div>
            <div v-if="selectedStyleSelectOption == 2" class="thisOr">
                <div class="formSectionLabel">One or more are required</div>
                <div class="form-group">
                    <label for="desturl" class="label">Web URL</label>
                    <input id="desturl" type="url" class="form-control" v-model="desturl"
                        placeholder="https://example.com"
                        pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" />
                </div>
                <div class="form-group">
                    <label for="phone" class="label">Phone Number</label>
                    <input id="phone" class="form-control" type="text" v-model="phone" />
                </div>
                <div class="form-group">
                    <label for="location" class="label">Location</label>
                    <input id="location" class="form-control" type="text" v-model="location" />
                </div>
            </div>
            <div v-if="selectedStyleSelectOption == 1" class="thisOr">
                <div class="formSectionLabel">Required</div>
                <div class="form-group">
                    <label for="redirecturl" class="label>">Redirect URL</label>
                    <input id="redirecturl" class="form-control" type="url" v-model="redirecturl"
                        placeholder="https://example.com"
                        pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" />
                </div>
            </div>
            <div v-if="messageNote" class="noteError">{{ messageNote }}</div>
            <div class="form-group">
                <input id="upcSupport" class="form-check-input" type="checkbox" v-model="upcSupport" default="false">
                &nbsp;&nbsp;<label for="upcSupport" class="label">UPC Support?</label>
            </div>
            <div v-if="upcSupport" class="thisOr">
                <div class="formSectionLabel">UPC Code Support (optional)</div>
                <div class="form-group">
                    <label for="upc" class="label">UPC Code</label>
                    <input id="upc" class="form-control" @keyup="checkUPC" type="text" v-model="upc" />
                    <div class="upcError">{{ upcError }}</div>
                    <div class="notes">
                        <small>Once 12 digits are entered the UPC barcode will be generated automatically when the code
                            is
                            saved. Correct check digit is required.</small>
                    </div>
                </div>
                <div class="form-group">
                    <input id="calcCheckDigit" class="form-check-input" type="checkbox" v-model="calcCheckDigit"
                        default="false">
                    &nbsp;&nbsp;<label for="calcCheckDigit" class="label">Calculate Check Digit?</label>
                    <div class="notes"><small>Type or paste in 11 digits and the form will calculate and insert the
                            final
                            check digit.</small>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="selectedDpiSelectOption" class="label">Barcode DPI</label>
                <select id="selectedDpiSelectOption" class="form-control" v-model="selectedDpiSelectOption">
                    <option v-for="dpiSelectOption in dpiSelectOptions" :value="dpiSelectOption.value">
                        {{ dpiSelectOption.name }}
                    </option>
                </select>
            </div>
            <button class="btn btn-primary">Add Code</button>
        </form>
    </div>
</template>

<script>
import { Buffer } from 'buffer'
import { v4 as uuidv4, v6 as uuidv6 } from 'uuid'
import shortUuid from 'short-uuid'
import { component, ref, onUpdated } from 'vue'
import { useCookies } from "vue3-cookies"
import { decycle } from "../../libs/libs.js"
const { cookies } = useCookies()
let theAuthCookie = cookies.get('theAuthCookie')
const uuidTranslator = shortUuid();
const IN_BROWSER = typeof window !== 'undefined'
const USER_AGENT = IN_BROWSER ? window.navigator?.userAgent : undefined
const IS_SAFARI = USER_AGENT?.includes('AppleWebKit') && !USER_AGENT?.includes('Chrome')
//const { v4: uuidv4 } = require('uuid');

function uuidToBase64(uuid) {
    const buffer = Buffer.from(uuid.replace(/-/g, ''), 'hex')
    const str = buffer.toString('base64')
    const strEnc = str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
    return strEnc
}

function calculateUPCCheckDigit(upc) {
    // Ensure the UPC is a string and remove any non-digit characters
    upc = upc.toString().replace(/\D/g, '');

    // Check if the UPC has the correct length (11 digits)
    if (upc.length !== 11) {
        return "Invalid UPC length";
    }

    // Calculate the check digit
    let sumOdd = 0;
    let sumEven = 0;

    for (let i = 0; i < upc.length; i++) {
        const digit = parseInt(upc[i]);

        if ((i + 1) % 2 === 1) {
            sumOdd += digit;
        } else {
            sumEven += digit;
        }
    }

    const total = sumOdd * 3 + sumEven;
    const checkDigit = (10 - (total % 10)) % 10;

    return checkDigit;
}

function isNumeric(str) {
    const num = Number(str);
    return !isNaN(num) && isFinite(num);
}

function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

function validCheckDigit(upc) {
    if (!upc)
        return false;
    if (!isNumeric(upc))
        return false;
    if (typeof upc != 'string')
        return false;
    if (upc.length < 12)
        return false;
    let calcCheckDigit = calculateUPCCheckDigit(upc.substring(0, 11));
    let checkDigit = upc.substring(11, 12)
    if (calcCheckDigit != checkDigit)
        return false;
    return true;
}

function validUrl(url) {
    try {
        if (!url || typeof url != 'string' || url.length == 0)
            return false;
        let urlO = new URL(url);
        //if (urlO && urlO.protocol != 'https:')
        //    return false;
        return true;
    } catch (err) {
        console.log(`validUrl error ${JSON.stringify(err)}`);
        return false;
    }
}

export default {
    components: {
    },
    data() {
        return {
            owneruserid: '',
            ownerusername: '',
            ownername: '',
            BusName: '',
            desturl: '',
            phone: '',
            location: '',
            upc: '12345678901',
            mappedUpc: '',
            codeid: uuidv6(),
            shortcodeid: null,
            sourceurl: '',
            redirecturl: '',
            calcCheckDigit: false,
            upcError: '',
            upcBarcode: '',
            qrCodeImageData: null,
            upcImageData: null,
            overlaidData: null,
            overlaidImageData: null,
            dpiSelectOptions: [
                {
                    name: "Web (96dpi)",
                    value: 96
                },
                {
                    name: "Print (384dpi)",
                    value: 384
                },
            ],
            selectedDpiSelectOption: 96,
            styleSelectOptions: [
                {
                    name: "Redirect Only",
                    value: 1
                },
                {
                    name: "Traditional",
                    value: 2
                },
            ],
            selectedStyleSelectOption: 1,
            BusNameNote: '',
            messageNote: '',
            upcSupport: false,
            displayUserStuffDetail: false,

        }
    },
    methods: {
        jsonPretty(val, indent = 2) {
            if (typeof val === "string") {
                val = JSON.parse(val)
            }
            return JSON.stringify(val, null, indent)
        },
        handleDisplayUserStuffDetail() {
            this.displayUserStuffDetail = !this.displayUserStuffDetail
        },
        checkUPC(evt) {
            let res
            //console.log(evt)
            if (!isNumeric(this.upc)) {
                this.upcError = "UPC must be a number"
                this.mappedUpc = ''
            }
            if (isNumeric(this.upc) && this.upc.length < 11) {
                this.upcError = "UPC must be at least 11 digits long"
                this.mappedUpc = ''
            }
            if (isNumeric(this.upc) && this.upc.length == 11) {
                res = calculateUPCCheckDigit(this.upc)
                this.upcError = `UPC with check digit - ${res} - ${this.upc}${res}`
                this.mappedUpc = ''
            }
            if (isNumeric(this.upc) && this.upc.length == 11 && this.calcCheckDigit) {
                res = calculateUPCCheckDigit(this.upc)
                this.upc = this.upc + res
                this.upcError = ''
                this.mappedUpc = this.upc
            }
            if (this.upc.length == 12) {
                let check = this.upc[11];
                let correctCheck = calculateUPCCheckDigit(this.upc.substring(0, 11));
                if (check != correctCheck) {
                    this.upcError = `Check digit invalid. Barcode will not generate. Check digit should be ${correctCheck}`
                } else {
                    this.upcError = ''
                    this.mappedUpc = this.upc
                }
            }
            if (this.upc.length > 12) {
                this.upcError = `UPC value has too many digits ${this.upc.length}`
            }

        },
        handleSubmit() {
            if (this.desturl.length == 0 && this.phone.length == 0 && this.location.length == 0 && this.redirecturl.length == 0) {
                this.messageNote = 'One of these must be populated'
                return
            } else {
                this.messageNote = ''
            }
            const now = new Date();
            const utcDate = now.toISOString();
            let code = {
                createdate: utcDate,
                lastmodify: utcDate,
                owneruserid: this.owneruserid,
                codeid: this.codeid,
                shortcodeid: this.shortcodeid,
                sourceurl: this.sourceurl,
                BusName: this.BusName,
                selectedDpiSelectOption: this.selectedDpiSelectOption,
                selectedDpiSelectOptionObject: this.dpiSelectOptions.find(dpiSelectOption => {
                    dpiSelectOption.value == this.selectedDpiSelectOption
                }),
                selectedStyleSelectOption: this.selectedStyleSelectOption,
                selectedStyleSelectOptionObject: this.styleSelectOptions.find(styleSelectOption => {
                    styleSelectOption.value == this.selectedStyleSelectOption
                }),
            }

            //validation before save - form should have it's own validation.
            if (validUrl(this.desturl))
                Object.assign(code, { desturl: this.desturl })
            if (this.phone && this.phone != '')
                Object.assign(code, { phone: this.phone })
            if (this.location && this.location != '')
                Object.assign(code, { location: this.location })
            if (validUrl(this.redirecturl))
                Object.assign(code, { redirecturl: this.redirecturl })
            if (this.upcSupport) {
                if (validCheckDigit(this.upc)) {
                    code = Object.assign(code, { calcCheckDigit: this.calcCheckDigit, upcSupport: this.upcSupport, upc: this.upc });
                } else {
                    console.log(`UPC invalid. Not saved ${this.upc} check digit invalid`)
                }
            }
            let url = `${process.env.VUE_APP_ROOT_URL}api/codes`
            fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(code),
            })
                .then((response) => response.json())
                .then((newcode) => {
                    console.log(`handleSubmit response ${JSON.stringify(newcode)}`)
                    this.$router.push(`/code/${newcode.codeid}`)
                })
                .catch((err) => {
                    console.log(`handleSubmit fetch failed ${JSON.stringify(err)}`)
                })

        },

    },
    mounted() {
        this.shortcodeid = uuidToBase64(this.codeid)////uuidTranslator.fromUUID(this.codeid)
        this.sourceurl = `${process.env.VUE_APP_ROOT_URL}codes?ad=${this.shortcodeid}`
        let setupUpc = ref('#upc')
        theAuthCookie = cookies.get('theAuthCookie')
        this.owneruserid = theAuthCookie.owneruserid
        let url = `${process.env.VUE_APP_ROOT_URL}api/users/${this.owneruserid}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                this.user = data[0]
                this.ownerusername = this.user.username
                this.ownername = `${this.user.fname} ${this.user.lname}`
            })
            .catch((err) => {
                console.log(`mounted load user err ${JSON.stringify(err)}`)
            })

        if (setupUpc.children && setupUpc.children[0]) {
            setupUpc.children[0].onChange((evt) => {
                let event = evt
                console.log(`child changed. ${event}`)
            })
        }
    },
    onChange() {
        console.log(`This is a test.... onChange`)
    },
    setup() {
    }

}
</script>

<style>
.xForm {
    max-width: 586px;
    margin: 20px auto;
    background: rgb(229, 229, 229);
    text-align: left;
    padding: 20px;
    border-radius: 10px;
}

.formSectionLabel {
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}

.form-control {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-items: flex-start;
    margin-top: 5px;
}

.form-control label {
    order: 1;
    color: #232323;
    /*display: inline-block;*/
    margin: 5px 0 1px;
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}

.form-control input {
    order: 2;
    /*display: block;*/
    margin: 5px 0 5px;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    border-bottom: 1px solid #ddd;
    color: #555;
}

.form-control select {
    order: 2;
    /*display: block;*/
    margin: 5px 0 5px;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    border-bottom: 1px solid #ddd;
    color: #555;
}

.form-control input[type="checkbox"] {
    order: 1;
    display: inline-block;
    width: 16px;
    margin: 5px 10px 5px 5px;
    position: relative;
    top: 2px;
}

div.notes {
    order: 99;
    margin-left: 10px;
}

div.upcError {
    order: 99;
    color: red;
    margin-left: 10px;
}

div.noteError {
    color: red;
    margin-left: 10px;
    margin-bottom: 20px;
}

input[required]+label:after {
    content: '*';
    color: red;
}

.thisOr {
    border: 3px solid blue;
    padding: 10px;
    margin: 10px;
}

textarea {
    border: 1px solid #ddd;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    height: 100px;
}

.btn-primary {
    display: block;
    margin: 20px auto 0;
    background: #00ce89;
    color: white;
    padding: 10px;
    border: 0;
    border-radius: 6px;
    font-size: 16px;
}

#qrcode {
    width: 50px;
    height: 50px;
}

.qrCodeImage {
    display: inline-block;
    border: 2px red;
    padding: 5px;
    margin: 5px;
    width: 100px;
    height: 100px;
    /*display: none;*/
}

.upcImage {
    display: inline-block;
    border: 2px green;
    padding: 5px;
    margin: 5px;
    /*width: 242px;
    height: 142px;*/
    /*display: none;*/
}

.overlaidImage {
    display: inline-block;
    width: 484px;
    height: 284px;
    border: 2px blue;
    padding: 5px;
    margin: 5px;
}

.overlaidImageCanvas {
    display: inline-block;
    width: 484px;
    height: 284px;
    border: 2px rgb(255, 0, 230);
    padding: 5px;
    margin: 5px;
}
</style>