<template>
    <div class="row">
        <div class="col-1">
            <router-link to="/"><img alt="QRCodeExperts.net" src="../assets/logo-qrcodeexperts.png"
                    class="logo" /></router-link>
        </div>
        <div class="col-11">
            <div class="row">
                <div class="col-12">
                    <div class="col-1 float-end">
                        <span :key="componentKey" v-if="theAuthCookie && theAuthCookie.owneruserid"><small>Welcome {{ ownername }} ({{
                            owneruserid }})</small></span>
                        <span v-else>No Login</span>
                    </div>
                    <div class="col-11">
                        <Navbar :key="$route.fullPath" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "./Navbar.vue"
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
const theAuthCookie = cookies.get('theAuthCookie')
import { getUser } from "../libs/libs.js"


export default {
    components: { Navbar },
    data() {
        return {
            user: null,
            owneruserid: null,
            ownerusername: null,
            ownername: null,
            theAuthCookie: theAuthCookie,
            componentKey:0,
        }
    },
    async mounted() {
        if (theAuthCookie && theAuthCookie.owneruserid) {
            this.user = await getUser(theAuthCookie.owneruserid)
            this.owneruserid = this.user.id
            this.ownerusername = this.user.username
            this.ownername = `${this.user.fname} ${this.user.lname}`
        }
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        }
    }

}
</script>

<style>
.logo {
    width: 80px;
}
</style>