<template>
    <p>Authorizing</p>
</template>

<script>
import { useRouter } from 'vue-router';
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
//import { methodThatForcesUpdate } from "../libs/libs.js" somehow doing this in libs is "bad" get all the warnings if I do this.

/* getCurrentInstance() is only avaliable in setup and lifecycle hooks.
import { getCurrentInstance } from 'vue';

https://github.com/vuejs/composition-api/issues/455
const methodThatForcesUpdate = () => {
    // ...
    const instance = getCurrentInstance();
    instance.proxy.forceUpdate();
    // ...
};*/

function methodThatForcesUpdate() {
    // ...
    this.$forceUpdate();  // Notice we have to use a $ here
    // ...
}

export default {
    methods: {
        /* doesn't work
        // from here https://michaelnthiessen.com/force-re-render/
        methodThatForcesUpdateA() {
            // ...
            this.$forceUpdate();  // Notice we have to use a $ here
            // ...
        }
        */
    },
    mounted() {
        const router = useRouter();
        const auth = this.$route.query.auth;
        let next = "/notauthorized";
        if (auth != null) {
            //do lookups into userbase authenticate and come back with an id and 
            //  an auth key (with expiration if needed)
            let authCookie = {
                auth: auth,
                owneruserid: auth,
            }
            cookies.set("theAuthCookie", JSON.stringify(authCookie))
            next = "/"
            //window.location.reload();
            //this.$router.go(0)
            //next("/");
        } else {
            cookies.remove("theAuthCookie")
        }
        this.interval = setTimeout(
            function () {
                //methodThatForcesUpdate()
                router.push({ path: next })
            },
            50
        );
    }
}
</script>