import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueCookies from "vue3-cookies";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// set secure, only https works
createApp(App)
    .use(VueCookies, {
        expireTimes: "8h",
//        secure: true,
    })
    .use(router)
    .mount("#app");
