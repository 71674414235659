<template>
    <p>Logging out</p>
</template>

<script>
import { useRouter } from 'vue-router'
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()

/* see notes in Auth.vue
//import { methodThatForcesUpdate } from "../libs/libs.js" somehow doing this in libs is "bad" get all the warnings if I do this.
import { getCurrentInstance } from 'vue';

const methodThatForcesUpdate = () => {
  // ...
  const instance = getCurrentInstance();
  instance.proxy.forceUpdate();
  // ...
};
*/

export default {
    methods: {
        /* doesn't work
        // from here https://michaelnthiessen.com/force-re-render/
        methodThatForcesUpdate() {
            // ...
            this.$forceUpdate();  // Notice we have to use a $ here
            // ...
        }
        */
    },
    mounted() {
        const router = useRouter()
        cookies.remove("theAuthCookie")
        const next = "/reload"
        this.interval = setTimeout(
            function () {
                //methodThatForcesUpdate()
                router.push({ path: next });
            },
            50
        );
    }
}
</script>